import React, { useEffect, useState, useRef } from 'react';
import { ToolbarForm } from '../toolbar-form/toolbar-form'
import { ContractFormDetails } from './ContractFormDetails';
import ValidationGroup from 'devextreme-react/validation-group';
import PutPostPatchFetch from '../../hooks/PutPostPatchFetch';
import './ContractForm.scss';
//import { withLoadPanel } from '../../../utils/withLoadPanel';

//const ContractFormDetailsWithLoadPanel = withLoadPanel(ContractFormDetails);

/** @typedef {import('../../types/contract').Contract} Contract */

/**
 * @param {Contract} data 
 * @param {boolean} isLoading 
 */
export const ContractForm = ({data, isLoading = false}) => {
  const [editing, setEditing] = useState(false);
  const [formData, setFormData] = useState(data);
  const dataRef = useRef(null);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const handleEditClick = () => {
    if(editing === false && formData) {
      dataRef.current = formData;
    } else {
      dataRef.current = undefined;
    }
    setEditing(!editing);
  };

  const onSaveClick = async ({ validationGroup }) => {
    // if (!validationGroup.validate().isValid) return;
      console.log("Saving Contract: ", formData);
      setEditing(false);
      const result = await PutPostPatchFetch('/v1/Contract/EditContract', 'PUT', formData);
  };

  const onCancelClick = () => {
    setFormData(dataRef.current);
    handleEditClick();
  };

  const updateField = (field, value) => {
    if(!formData) 
      return;
    console.log(formData);
    console.log(field);
    console.log(value);
    formData[field] = value;
    //setFormData({ ...formData, ...{ [field]: value } });
    console.log(formData);
  };

  const updateObject = (item, key, field, value) => {
    if(!formData) 
      return;
    console.log(formData);
    console.log(field);
    console.log(value);
    formData[item][key] = {...formData[item][key], [field]: value};
  //  setFormData(formData);
  //   const arr = [];
  //   const obj = {field: value};
  //   arr[pos] = obj;
  //   formData[item] = arr
  //  Object.keys
    //setFormData({ ...formData, ...{ [field]: value } });
    console.log(formData);
  };

  return (
    <div className='contact-form'>
      <ValidationGroup>
        <ToolbarForm toggleEditing={handleEditClick} onSaveClick={onSaveClick} editing={editing} onCancelClick={onCancelClick} />
        <ContractFormDetails
          loading={isLoading}
          hasData={!formData}
          data={formData}
          editing={editing}
          updateField={updateField}
          updateObject={updateObject}
        //   panelProps={{
        //     container: '.contact-form',
        //     position: { of: '.contact-form' },
        //   }}
        />
      </ValidationGroup>
    </div>
  );
};