import { DateBox, Validator } from "devextreme-react";
import { RequiredRule, CustomRule } from "devextreme-react/data-grid";
import IsNullOrEmpty from "../IsNullOrEmpty";
import { Dimensions } from "../../Enums/dimensions";
import "./BaseTimeField.scss";

const { Heights: { inputHeight } } = Dimensions;

/**
 * @module BaseTimeField
 *  
 * * This is the base of a TimeField for RMS.
 * It uses a textfield with type of "time" as the root UI Component.
 * This field can be used to input a time from a range between 12:00 AM
 * and 11:30 PM at 30-minute intervals format, or they can type in their
 * own time value (in the required format), default HH:mm (e.g., 14:30 for 2:30 PM)
 * and can be expanded in the future to accommidate other date and 
 * time values as needed.
 * 
 * @param {Object} inputAttr: Sets any global HTML attributes to the component. Optional.
 * @param {String} label: The visible label (text) for the time field.
 * @param {Boolean} isValid: Specifies if the field's value is valid. Optional.
 * @param {String | Date | Number} value: The initial state value for the time field. Defaults to Null.
 * @param {Function} valueUpdatedByUser: Callback function when the value changes.
 * @param {String} validationMessage: Custom validation message. Optional.
 */
export const BaseTimeField = ({
    inputAttr,
    isValid,
    label,
    value,
    valueUpdatedByUser,
    validationMessage,
    defaultTime = "13:00" // Set default time to midday
}) => {
    return (
        <span className="inline__span--timefield-wrapper">
            <DateBox
                inputAttr={inputAttr}
                isValid={isValid}
                label={label}
                type="time" // Type set to "time" for time input
                useMaskBehavior={true}
                value={value || defaultTime}
                onValueChanged={valueUpdatedByUser}
                height={inputHeight}
                displayFormat="HH:mm" // Ensure the time is in 24-hour format
            >
                <Validator>
                    <RequiredRule message="This field is required" />
                    <CustomRule
                        message={validationMessage}
                        reevaluate={true}
                        validationCallback={() => !IsNullOrEmpty(value)}
                    />
                </Validator>
            </DateBox>
        </span>
    );
};
