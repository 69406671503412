const SingleRegionSelectFieldDevEx = () => {
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        // here we will reset all values to default so that we only display one thing at a time
        // i.e. loading, data, or an error
        setError(false);
        setRegions(null);
        setLoading(true);
        const result = await GetFetch('/v1/Region');
        const { Success, Message, Errors } = result;
        if (Success) {
            setError(false);
            setRegions(Message);
        }
        else {
            setError(true);
            if (Errors[0].Message === "No records found at the moment") {
                setErrorMessage("Something went wrong. Please contact your administrator.");
            }
            else {
                setErrorMessage(Errors[0].Message);
            }
        }
        setLoading(false);
    };
    return (
        <>Not Implemented, see https://js.devexpress.com/Documentation/Guide/UI_Components/DropDownBox/Getting_Started_with_DropDownBox/, and https://js.devexpress.com/Documentation/Guide/UI_Components/DropDownBox/Synchronize_with_the_Embedded_Element/ for working with a complex data source</>
    )
}
export default SingleRegionSelectFieldDevEx;