
import { PostExhibit } from '../../../src/types/postExhibit';

/** @type {PostExhibit} */
export const DefaultPostExhibit = {
    PostExhibitId: null,
    TaskOrderId: null,
    ClonedPostExhibitId: null,
    TaskOrderNumber: '',
    PostExhibitStatusId: null,
    Name: '',
    FiscalYear: null,
    StartDate: '',
    EndDate: '',
    TaskOrder: {
        TaskOrderId: null,
        Number: '',
        StartDate: '',
        EndDate: '',
        AwardDate: '',
        VendorName: '',
        LineNumber: '',
        ContractId: null,
        ProductCodeId: null,
        ProductDescriptionId: null,
        RegionId: null,
        TaskOrderTypeId: null,
        ModifyTaskOrderId: null,
        ItemNumber: null,
        FinancialCodes: {
            FinancialCodeId: null,
            TaskOrderId: null,
            AccountCode: '',
            AccSequenceNumber: '',
            AccObligatedAmount: null,
            TreasuryAccSymbol: '',
            CageCode: ''
        },
        PostExhibit: []
    },
    PostExhibitStatus: {
        PostExhibitStatusId: null,
        Name: ''
    },
    CostEstimates: [],
    Posts: {
        PostId: null,
        CLIN: '',
        TaskOrderId: null,
        PostLocationId: null,
        FeeTypeId: null,
        ScreenerId: null,
        BuildingId: null,
        PostStatusId: null,
        PrimaryPostTypeId: null,
        SecondaryPostTypeId: null,
        SecurityLevelId: null,
        HourlyRate: null,
        StartDate: '',
        EndDate: '',
        AdditionalServiceHours: null,
        TotalServiceHours: null,
        TotalServiceCosts: null,
        Building: {
            BuildingId: null,
            SequenceNumber: '',
            Name: '',
            Status: '',
            Facility: '',
            RegionNumber: null,
            Location: {
                Id: null,
                Address1: '',
                Address2: '',
                CityName: '',
                State: '',
                ZipCode: ''
            }
        },
        FeeType: {
            FeeTypeId: null,
            FeeTypeName: ''
        },
        PostLocation: {
            PostLocationId: null,
            Name: ''
        },
        PostStatus: {
            PostStatusId: null,
            Name: ''
        },
        PrimaryPostType: {
            PostTypeId: null,
            Name: ''
        },
        SecondaryPostType: {
            PostTypeId: null,
            Name: ''
        },
        Screener: {
            ScreenerId: null,
            Name: ''
        },
        SecurityLevel: {
            SecurityLevelId: null,
            Name: ''
        },
    }
};
