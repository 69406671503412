import React, { useState, useEffect, useRef, useCallback } from "react";
import { BaseButton } from "../../components/BaseButton/BaseButton";
import { BaseNumberBox } from "../../components/BaseNumberBox/BaseNumberBox";
import { FormTextBox } from '../../components/FormTextBox/FormTextBox';
import { ROUTE_CREATE_EDIT_POST_EXHIBIT, ROUTE_POST_EXHIBITS_LIST_PAGE } from "../../routes/Routes";
import "../css/PostExhibits.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { ColCountByScreen, Form, Item as FormItem, GroupItem, TabbedItem, TabPanelOptions, Tab, Item } from "devextreme-react/form";
import GetFetch from "../../hooks/GetFetch";
import { SelectBox, ValidationGroup } from "devextreme-react";
import { ToolbarForm } from "../../components/toolbar-form/toolbar-form";
import DataGridPost from "./DataGridPost";
import PutPostPatchFetch from "../../hooks/PutPostPatchFetch";
import { FormDateBox } from "../../components/FormDateBox/FormDateBox";
import { DefaultPostExhibit } from "./DefaultPostExhibit";
import IsNullOrEmpty from "../../components/IsNullOrEmpty";

const URL_contracts = '/v1/Contract/GetContracts';
const URL_clonePostExhibit = '/v1/PostExhibit/ClonePostExhibit/'; //+ PostExhibitId
const URL_updatePostExhibit = '/v1/PostExhibit/UpdatePostExhibit';
const URL_addPostExhibit = '/v1/PostExhibit/AddPostExhibit';

export default function PostExhibitForm() {
    const navigate = useNavigate();
    const location = useLocation();
    const [taskOrders, setTaskOrders] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [selectedContractId, setSelectedContractId] = useState(null);
    const [selectedTaskOrderNumber, setSelectedTaskOrderNumber] = useState(null);
    const [editing, setEditing] = useState(false);
    const [isNewPostExhibit, setIsNewPostExhibit] = useState(false);
    const [posts, setPosts] = useState(null);
    const dataRef = useRef(null);
    const [postExhibitFormData, setPostExhibitFormData] = useState(DefaultPostExhibit);

    useEffect(() => {
        if (location.state) {
            setPostExhibitFormData(location.state?.postExhibit);
            setPosts(location.state?.postExhibit?.Posts);
        } else {
            setEditing(true);  
            setIsNewPostExhibit(true);           
            fetchAndSet(URL_contracts, setContracts);
        }
    }, []);

    const fetchAndSet = async (url, setter) => {
        const { Errors, Message, Success } = await GetFetch(url);
        if (Success === true) {
            setter(Message);
        } else {
            console.error(Errors[0].Message);
        }
    }

    const updateField = useCallback((field, value) => {
        setPostExhibitFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    }, []);

    const handleContractSelection = useCallback((selectedItem) => {
        if (selectedItem && selectedItem.ContractId !== selectedContractId) {
            setSelectedContractId(selectedItem.ContractId); 
            const contract = contracts.find(contract => contract.ContractId === selectedItem.ContractId);
            if (contract && contract.TaskOrders) {
                setTaskOrders(contract.TaskOrders);
            } else {
                setTaskOrders([]);
            }

            updateField('TaskOrder.ContractId', selectedItem.ContractId);
        }
    }, [contracts, selectedContractId, updateField]);

    const handleTaskOrderSelection = useCallback((selectedItem) => {
        if (selectedItem && selectedItem.Number !== selectedTaskOrderNumber) {
            setSelectedTaskOrderNumber(selectedItem.Number);
            updateField('TaskOrderNumber', selectedItem.Number);
        }
    }, [selectedTaskOrderNumber, updateField]);

    const onClonePostExhibit = async () => {
        const { Errors, Message, Success } =
            await PutPostPatchFetch(URL_clonePostExhibit + postExhibitFormData.PostExhibitId,
                'POST', null
            );
        if (Success) {
            console.log("cloned Post Exhibit");
        }
    }

    const onSaveClick = async ({ validationGroup }) => {
        if (!validationGroup.validate().isValid) return;

        setEditing(false);
        if (postExhibitFormData.PostExhibitId === 0
            || IsNullOrEmpty(postExhibitFormData.PostExhibitId)) {
            //Save new postExhibit
                const { Success, Errors, Message } = await PutPostPatchFetch(URL_addPostExhibit, 'POST', postExhibitFormData);

        } else {
            const { Success, Errors, Message } = await PutPostPatchFetch(URL_updatePostExhibit, 'POST', postExhibitFormData);
        }
       
        if (Success) {
            console.log('saved changed');
            navigate(ROUTE_POST_EXHIBITS_LIST_PAGE.withSlash);
        } else {
            setError(true);
            setIsNewPostExhibit(true);
        }
    }

    const handleEditClick = () => {
        if (editing === false && postExhibitFormData) {
            dataRef.current = postExhibitFormData;
        } else {
            dataRef.current = undefined;
        }
        setEditing(!editing);
    }

    const onCancelClick = () => {
        setPostExhibitFormData(dataRef.current);
        handleEditClick();
    }

    const getTaskOrderTypeString = () => {
        const taskOrderTypeId = postExhibitFormData?.TaskOrder?.TaskOrderTypeId || 0;
        switch (taskOrderTypeId) {
            case 1:
                return "Base";
            case 2:
                return "Option";
            case 3:
                return "Modification";
            default:
                return "";
        }
    }

    return (
        <div>
            <h1>{"Post Exhibit Details"}</h1>
            {!isNewPostExhibit &&
                <BaseButton
                    label={"Clone Post Exhibit"}
                    onClick={onClonePostExhibit}
                    variant={"contained"}
                    disabled={isNewPostExhibit}
                />
            }
            <div className="inline_div--form-wrapper">
                <ValidationGroup>
                    <ToolbarForm toggleEditing={handleEditClick} onSaveClick={onSaveClick} editing={editing} onCancelClick={onCancelClick} />
                    <Form>
                        <GroupItem
                            caption={isNewPostExhibit ? ''
                                : "Post Exhibit ID: " + (
                                    !IsNullOrEmpty(postExhibitFormData?.PostExhibitId) ? postExhibitFormData.PostExhibitId : ''
                                )} >
                            <TabbedItem >
                                <TabPanelOptions deferRendering={false} />
                                <Tab title="Contract Summary" >
                                    {/* Contract Number, contract Personnel*/}
                                    {isNewPostExhibit ?
                                        (
                                            <GroupItem colCount={4}>
                                                <FormItem>
                                                    <SelectBox
                                                        inputAttr={{ 'aria-label': 'ContractId' }}
                                                        label='Contract Id'
                                                        dataSource={contracts}
                                                        displayExpr={'Number'}
                                                        valueExpr={'ContractId'}
                                                        value={selectedContractId}
                                                        onSelectionChanged={(e) => handleContractSelection(e.selectedItem)}
                                                        readOnly={!editing}
                                                        searchEnabled={true}
                                                    />
                                                </FormItem>
                                            </GroupItem>
                                        ) :
                                        (
                                            <GroupItem colCount={4}>
                                                <FormItem>
                                                    <FormTextBox
                                                        label='Contract Id'
                                                        value={postExhibitFormData?.TaskOrder?.ContractId}
                                                        isEditing={editing}
                                                        onValueChange={e => updateField('TaskOrder.ContractId', e)}
                                                    />
                                                </FormItem>
                                                <FormItem>
                                                    <FormTextBox
                                                        label='CO'
                                                        value={''}
                                                        isEditing={false}
                                                        onValueChange={e => updateField('', e)}
                                                    />
                                                </FormItem>
                                                <FormItem>
                                                    <FormTextBox
                                                        label='COR'
                                                        value={''}
                                                        isEditing={false}
                                                        onValueChange={e => updateField('', e)}
                                                    />
                                                </FormItem>
                                                <FormItem>
                                                    <FormTextBox
                                                        label='ACOR'
                                                        value={''}
                                                        isEditing={false}
                                                        onValueChange={e => updateField('', e)}
                                                    />
                                                </FormItem>
                                            </GroupItem>
                                        )
                                    }
                                </Tab>
                                <Tab title="Task Order Summary" >
                                    {/* T.O Number, start date, end date, vendor, region, type*/}
                                    {isNewPostExhibit ? (
                                        <GroupItem colCount={4}>
                                            <FormItem>
                                                <SelectBox
                                                    inputAttr={{ 'aria-label': 'Task Order Number' }}
                                                    label='Task Order Number'
                                                    dataSource={taskOrders}
                                                    displayExpr={'Number'}
                                                    valueExpr={'Number'}
                                                    value={selectedTaskOrderNumber}
                                                    onSelectionChanged={(e) => handleTaskOrderSelection(e.selectedItem)}
                                                    readOnly={!editing}
                                                    searchEnabled={true}
                                                    disabled={!taskOrders.length}
                                                />
                                            </FormItem>
                                        </GroupItem>
                                    ) :
                                        (
                                            <GroupItem colCount={4}>
                                                <FormItem>
                                                    <FormTextBox
                                                        label='Task Order Number'
                                                        value={postExhibitFormData?.TaskOrderNumber}
                                                        isEditing={false}
                                                        onValueChange={e => updateField('TaskOrderNumber', e)}
                                                    />
                                                </FormItem>
                                                <FormItem>
                                                    <FormDateBox
                                                        label='Start Date'
                                                        value={postExhibitFormData?.TaskOrder?.StartDate}
                                                        isEditing={false}
                                                        onValueChange={e => updateField('TaskOrder.StartDate', e)}
                                                    />
                                                </FormItem>
                                                <FormItem>
                                                    <FormDateBox
                                                        label='End Date'
                                                        value={postExhibitFormData?.TaskOrder?.EndDate}
                                                        isEditing={false}
                                                        onValueChange={e => updateField('TaskOrder.EndDate', e)}
                                                    />
                                                </FormItem>
                                                <FormItem>
                                                    <FormTextBox
                                                        label='Vendor'
                                                        value={postExhibitFormData?.TaskOrder?.VendorName}
                                                        isEditing={false}
                                                        onValueChange={e => updateField('TaskOrder.VendorName', e)}
                                                    />
                                                </FormItem>
                                                <FormItem>
                                                    <FormTextBox
                                                        label='Region'
                                                        value={postExhibitFormData?.TaskOrder?.RegionId}
                                                        isEditing={false}
                                                        onValueChange={e => updateField('TaskOrder.RegionId', e)}
                                                    />
                                                </FormItem>
                                                <FormItem>
                                                    <FormTextBox
                                                        label='Type'
                                                        value={getTaskOrderTypeString()}
                                                        isEditing={false}
                                                        onValueChange={e => updateField('TaskOrder.TaskOrderTypeId', e)}
                                                    />
                                                </FormItem>
                                            </GroupItem>
                                        )
                                    }
                                </Tab>
                                <Tab title="Post Exhibit" >
                                    <GroupItem colCount={4}>
                                        <FormItem>
                                            <FormTextBox
                                                label='Name'
                                                value={postExhibitFormData?.Name}
                                                isEditing={editing}
                                                onValueChange={e => updateField('Name', e)}
                                            />
                                        </FormItem>
                                        <FormItem>
                                            <FormTextBox
                                                label='Fiscal Year'
                                                value={postExhibitFormData?.FiscalYear}
                                                isEditing={editing}
                                                onValueChange={e => updateField('FiscalYear', e)}
                                            />
                                        </FormItem>
                                        <FormItem>
                                            <FormTextBox
                                                label='Status ID'
                                                value={postExhibitFormData?.PostExhibitStatusId}
                                                isEditing={editing}
                                                onValueChange={e => updateField('PostExhibitStatusId', e)}
                                            />
                                        </FormItem>
                                        <FormItem>
                                            <FormDateBox
                                                label='Start Date'
                                                value={postExhibitFormData?.StartDate}
                                                isEditing={editing}
                                                onValueChange={e => updateField('StartDate', e)}
                                            />
                                        </FormItem>
                                        <FormItem>
                                            <FormDateBox
                                                label='End Date'
                                                value={postExhibitFormData?.EndDate}
                                                isEditing={editing}
                                                onValueChange={e => updateField('EndDate', e)}
                                            />
                                        </FormItem>
                                        <FormItem>
                                            <FormTextBox
                                                label='Cloned PostExhibit ID'
                                                value={postExhibitFormData?.ClonedPostExhibitId}
                                                isEditing={editing}
                                                onValueChange={e => updateField('ClonedPostExhibitId', e)}
                                            />
                                        </FormItem>
                                    </GroupItem>
                                </Tab>
                            </TabbedItem>
                        </GroupItem>
                        <GroupItem caption="Posts">
                            <DataGridPost data={postExhibitFormData} editing={editing} />
                        </GroupItem>
                    </Form>
                </ValidationGroup>
            </div>
        </div>

    )
}